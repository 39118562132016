import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {
    Alert,
    Button,
    Col,
    Collapse,
    Drawer,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Slider,
    Space,
    Spin,
    Tabs
} from "antd";
import {AppContext} from "../../../AppProvider";
import {useForm} from "antd/es/form/Form";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import APITProvider from "../../../service/api";
import DrawerEditProps from "../../../classes/DrawerEdit/DrawerEditProps";
import dtoTenantEdit from "../../../Models/Tenants/dtoTenantEdit";
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Tenant from "../../../Models/Tenants/Tenant";
import {ApartmentOutlined, InfoCircleOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import SwitchWrapper from "../../MyPlaybooks/SwitchWrapper";
import DomainSelect2 from "../../domains/DomainSelect2";

const TenantEditDrawer = forwardRef<DrawerRef,DrawerEditProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const queryClient = useQueryClient()
    const APIT = new APITProvider(token, true);

    // State
    const [TenantID, SetTenantID] = useState<string>();
    const [Open, SetOpen] = useState(false);

    // Refs
    const [form] = useForm();
    const [formBehaviourPhish] = useForm();
    const [formBehaviourMalware] = useForm();

    // React queries
    const {
        data: TenantData,
        isLoading: Loading,
        refetch: refetch,
    }  = useQuery<Tenant>({
            queryKey: ["tenant", TenantID],
            queryFn: TenantID !== undefined ? async() => await APIT.getTenant(TenantID) : undefined,
            enabled: Open
        }
    );

    // Funcs
    const showDrawer = (id:string) => {
        SetTenantID(id);
        SetOpen(true);
    }

    const handleSave = async () => {

        if(TenantID !== undefined)
        {
            const modal = Modal.success({
                title: 'Saving',
                content: `Tenant is being saved, please hold.`,
            });

            let dtoObject:dtoTenantEdit = form.getFieldsValue(true);
            let behaviourTenantPhish:any = formBehaviourPhish.getFieldsValue(true);
            let behaviourTenantMalware:any = formBehaviourMalware.getFieldsValue(true);

            dtoObject.Behaviour = {
                Phish: behaviourTenantPhish,
                Malware: behaviourTenantMalware
            }

            APIT.putTenant(TenantID,dtoObject).then(() => {

                if(onChange !== undefined)
                {
                    onChange();
                }
                queryClient.invalidateQueries({ queryKey: ['tenant'] })
                modal.destroy();
                handleClose();
            }).catch(() => {
                modal.destroy();
            });

        }
    }

    const handleClose = () => {
        SetTenantID("");
        SetOpen(false);
    }

    // @ts-ignore
    return (
        <Drawer
            title="Edit tenant"
            onClose={handleClose}
            width={800}
            afterOpenChange={(c) => {
                form.resetFields();
                formBehaviourPhish.resetFields();
                formBehaviourMalware.resetFields();
            }}
            extra={
                <Space>
                    <Button type="primary" icon={<SaveOutlined/>} onClick={() => handleSave()}>Save</Button>
                    <Button icon={<SelectOutlined/>}>Revert</Button>
                </Space>
            }
            open={Open}>

            {Loading && <Spin/>}

            {!Loading &&
                <Tabs defaultActiveKey="Detail">

                    <Tabs.TabPane
                        tab={<span><InfoCircleOutlined />Detail</span>}
                        key="Detail">
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={TenantData}
                                onFinish={handleSave}>

                                <Form.Item
                                    label="Descriptive Name"
                                    name="Name"
                                    rules={[{required: true, message: 'Please enter descriptive name'}]}>
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Tenant Description"
                                    name="Description">
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Tenant Base Domain"
                                    name="BaseDomain">
                                    <DomainSelect2 Use="Office365"/>
                                </Form.Item>


                                <Form.Item
                                    label="Enable Simulation"
                                    tooltip="Tenants that have simulation turned on will generate traffic without playbooks running. They use the behaviour to control this."
                                    name="SimulationEnabled">
                                    <SwitchWrapper />
                                </Form.Item>

                            </Form>
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={<span><InfoCircleOutlined />Behaviour</span>}
                        key="Behaviour">
                        <Form
                            form={formBehaviourPhish}
                            layout="vertical"
                            initialValues={(TenantData !== undefined &&
                                            TenantData.Behaviour !== undefined &&
                                            TenantData.Behaviour !== null &&
                                            TenantData.Behaviour.Phish !== undefined &&
                                            TenantData.Behaviour.Phish !== null) ? TenantData.Behaviour.Phish : undefined}
                            onFinish={handleSave}>

                            <Form.Item name="SpoofIntra" label="Phish Spoof Intraorg"><Slider max={1000}/></Form.Item>
                            <Form.Item name="SpoofExtra" label="Phish Spoof Interorg"><Slider max={1000}/></Form.Item>
                            <Form.Item name="ImpersonationDomain" label="Phish Impersonation Domain"><Slider max={1000}/></Form.Item>
                            <Form.Item name="ImpersonationUser" label="Phish Impersonation User"><Slider max={1000}/></Form.Item>

                        </Form>

                        <Form
                            form={formBehaviourMalware}
                            layout="vertical"
                            initialValues={(TenantData !== undefined &&
                                            TenantData.Behaviour !== undefined &&
                                            TenantData.Behaviour !== null &&
                                            TenantData.Behaviour.Malware !== undefined &&
                                            TenantData.Behaviour.Malware !== null) ? TenantData.Behaviour.Malware : undefined}
                            onFinish={handleSave}>

                            <Form.Item name="SpoofIntra" label="Malware Spoof Intraorg"><Slider max={1000}/></Form.Item>
                            <Form.Item name="SpoofExtra" label="Malware Spoof Interorg"><Slider max={1000}/></Form.Item>
                            <Form.Item name="ImpersonationDomain" label="Malware Impersonation Domain"><Slider max={1000}/></Form.Item>
                            <Form.Item name="ImpersonationUser" label="Malware Impersonation User"><Slider max={1000}/></Form.Item>

                        </Form>
                    </Tabs.TabPane>
                </Tabs>
            }

        </Drawer>

    )

});

export default TenantEditDrawer;