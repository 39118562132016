import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {Button, Dropdown, Menu, Space, Spin, Table, Tag, Typography} from "antd";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
interface LabUserAttestationTextProps {
    LabUserID: string
}

const LabUserAttestationText = (props: LabUserAttestationTextProps)  =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    console.dir(props.LabUserID);

    const {
        data: AttestData,
        isLoading: isLoading,
        refetch: refetch
        }  = useQuery({
            queryKey: ["labuserattest", props.LabUserID],
            queryFn: props.LabUserID !== undefined ? async() => await APIT.labUserAttestationGet(props.LabUserID) : undefined,
            enabled: props.LabUserID !== undefined && props.LabUserID !== null ? true : false
        }
    );

    // return on change properties

    if(isLoading)
    {
        return <Spin/>
    }

    if(!isLoading && AttestData)
    {
        return <Space>
            <Typography.Text>{AttestData.BusinessCase}</Typography.Text>
        </Space>
    }

    return <></>
}

export default LabUserAttestationText;