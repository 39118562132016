import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie'
import {message} from "antd";
import Text from "antd/es/typography/Text";

export default class AttestAPI extends React.Component
{

    useMessageErrorHandler = false;

    urlApi = process.env.REACT_APP_urlApi;

    handleError(error, rejector) {

        let MainText = "Unhandled";
        let Message = ":( Oh oh! Unhandled error occurred!";

        if(error !== undefined)
        {

            if(error.response !== undefined && error.response.data !== undefined)
            {

                // Specific response code handling
                if(error.response.status === 401) {
                    // Default message
                    MainText = "Unauthorized";
                    Message = "Unauthorized. You may not have the permissions required to perform this operation :(";
                } else if (error.response.status===400) {
                    MainText = "Bad Request";
                    Message = "Something that you tried to do wasn't right. The API didn't tell us what it was though, this is a bug, log it. :("
                }

                if (typeof error.response.data === "string") {
                    Message = error.response.data;
                }

            }


        }

        Message = <span><Text strong>{MainText} </Text><Text>{Message}</Text> </span>;

        if(this.useMessageErrorHandler)
        {
            let messageConfig = {
                content: Message,
                duration: 15,
            }

            console.dir(messageConfig);

            message.error(messageConfig);
        }

        rejector(new error("Error"));


    }

    getAttestationData(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "LabUser/" + id + "/attestationdata", {
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    attest(id,dto)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "LabUser/" + id + "/attest", dto, {

            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

}