import React, {useContext} from 'react'
import {AppContext} from "../../AppProvider";
import Text from "antd/es/typography/Text";
import {Avatar, Descriptions, Popover, Space, Spin} from "antd";
import APIProvider from "../../service/api2";
import DateTimeView from "../misc/DateTimeView";
import {useQuery} from "@tanstack/react-query";

const AdminDisplay = ({Admin, showAvatar, showName}) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // React Query
    const {
        data: AdminResult,
        isLoading: isLoading
    } = useQuery(
        ["operator", Admin],
        async() => await API.getOperator(Admin),
        {
            staleTime: 300000
        }
    );

    if(isLoading) {
        return <Spin />
    }

    if(Admin !== undefined)
    {
        return (
            <Popover
                content={
                    <Descriptions bordered column={1} size="small">
                        <Descriptions.Item label="ID">
                            <Space>
                                <Avatar src={AdminResult.Avatar}/>
                                <Text>{AdminResult.id}</Text>
                            </Space>
                        </Descriptions.Item>

                        <Descriptions.Item label="Name">
                            {AdminResult.firstname} {AdminResult.surname}
                        </Descriptions.Item>

                        <Descriptions.Item label="Last Seen">
                            <DateTimeView DateTime={AdminResult.LastAuth}/>
                        </Descriptions.Item>
                    </Descriptions>
                }>

                <Space>
                    { showAvatar &&
                        <Avatar src={AdminResult.Avatar} size="large"/>
                    }

                    { showName &&
                        <Text>{AdminResult.firstname} {AdminResult.surname}</Text>
                    }
                </Space>
            </Popover>
        );
    }

    return <></>

}
export default AdminDisplay