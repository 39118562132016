import React, {forwardRef, RefObject, useContext, useImperativeHandle, useRef, useState} from "react";
import {Avatar} from "antd";
import AdminDisplay from "../AdminDisplay";
import AdminSelectDrawer from "./AdminsSelectDrawer";
import AdminSelectDrawerRef from "./AdminsSelectDrawerRef";
import { PlusOutlined } from "@ant-design/icons";

interface AdminSelectProps {
    value:string[],
    onChange?: ([]) => void
}

const AdminsSelect = ({value, onChange}:AdminSelectProps) => {

    // state
    const [InternalState,SetInternalState] = useState<string[]>([]);

    // refs
    const refAdminSelectDrawer = useRef<AdminSelectDrawerRef>() as RefObject<AdminSelectDrawerRef>;

    // functions

    const handleAddClick = () => {
        if (refAdminSelectDrawer.current !== null)
        {
            refAdminSelectDrawer.current.showDrawer();
        }
    }

    const handleChange = (val:string[]) => {
        if(onChange!==undefined)
        {
            onChange(val);
        }
    }

    return (
        <Avatar.Group size="large">
            <AdminSelectDrawer ref={refAdminSelectDrawer} Title="Select Admins" Admins={value}
                               onChange={(val: string[]) => handleChange(val)}/>
                <>
                { value && value.map((a) => {
                    return (
                        <AdminDisplay Admin={a} showAvatar="true" showName={false} />
                    )
                })

                }
                <Avatar style={{ backgroundColor: '#1890ff' }} icon={<PlusOutlined />} size="small" onClick={handleAddClick}/>
            </>
        </Avatar.Group>
    )

};

export default AdminsSelect;