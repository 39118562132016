import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react'
import {
    Form,
    Input,
    InputNumber,
    message,
    Select,
    Space,
    Typography,
    Tag
} from "antd";
import Text from "antd/es/typography/Text";
import SwitchWrapper from "../MyPlaybooks/SwitchWrapper";
import FileGenerationSelector from "../files/FileGenerationSelector";
import EmailActionInput from "./EmailActions/EmailActionInput.react";
import ExpectedAlertsInput from "./ExpectedAlerts/ExpectedAlertsInput.react";
import HashTableInput from "./HashTable/HashTableInput.react";
import WebSessionAction from "./WebSessionAction/WebSessionAction.react";
import WebsitePayloadFormItem from "../Website/WebsitePayloadFormItem/WebsitePayloadFormItem";
import HTMLInput from "../FormItems/HTMLInput/HTMLInput";
import PlaybookTextInput from "./PlaybookTextInput/PlaybookTextInput";

const TaskItemInput = ({Meta, PlaybookVariables, DependencyVariables}) => {

    console.dir(PlaybookVariables);

    // State items
    const [optionsPlaybook, SetOptionsPlaybook] = useState([]);
    const [optionsDependencies, SetOptionsDependencies] = useState([]);
    const [optionsStatic, SetOptionsStatic] = useState([]);
    const [inputType, SetInputType] = useState(undefined);

    useEffect(() => {
        let newOptionsPlaybook =  [];

        // Create a VariableOptions based on Type
        if(PlaybookVariables !== undefined && Meta)
        {
            PlaybookVariables.forEach((v) => {
                if(Meta.Type.includes(v.VarType))
                {
                    newOptionsPlaybook.push({
                        value: v.entryText,
                        label: <Space>
                            <Tag>{v.id}</Tag>
                            <Text>{v.Description}</Text>
                        </Space>
                    })
                }
            })
        }

        console.dir(newOptionsPlaybook);

        SetOptionsPlaybook(newOptionsPlaybook);
    }, [PlaybookVariables, Meta])

    useEffect(() => {
        let newOptionsDependencies = [];

        if(DependencyVariables !== undefined && Meta)
        {
            DependencyVariables.forEach((v) => {

                let entryIsRelevant = false;

                if(Meta.Type.includes(v.Type))
                {
                    entryIsRelevant = true;
                }

                if(entryIsRelevant)
                {
                    newOptionsDependencies.push({
                        value: v.id,
                        label: <Space>
                            <Tag>{v.id}</Tag>
                            <Text>{v.Description}</Text>
                        </Space>
                    })
                }
            })
        }


        SetOptionsDependencies(newOptionsDependencies);

    }, [DependencyVariables, Meta]);

    useEffect(() => {
        if(Meta !== undefined)
        {
            // Determine input type
            if(Meta.Type.includes("FileReference") ||
                Meta.Type.includes("Agent") ||
                Meta.Type.includes("Identity") ||
                Meta.Type.includes("EmailAddress") ||
                Meta.Type.includes("EmailReference") ||
                Meta.Type.includes("EmailTemplate") ||
                Meta.Type.includes("WebSession") ||
                Meta.Type.includes("TeamsChatID") ||
                Meta.Type.includes("WebsiteReference"))
            {
                SetInputType("entity");
            } else {
                SetInputType(Meta.Type[0])
            }
        }
    }, [Meta]);

    if(inputType==="AgentCapability")
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <Select
                    mode={Meta.Multiple ? "multiple" : undefined}
                    allowClear={true}>
                    <Select.Option value="TOR">
                        <Space direction="vertical">
                            <Text>TOR</Text>
                            <Text type="secondary">Run from an agent connected to TOR network</Text>
                        </Space>
                    </Select.Option>
                </Select>
            </Form.Item>
        );
    }

    if(inputType==="AgentCharacteristic")
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <Select
                    mode={Meta.Multiple ? "multiple" : undefined}
                    allowClear={true}>

                    <Select.Option value="TOR">
                        <Space direction="vertical">
                            <Text>TOR</Text>
                            <Text type="secondary">Agent connected to the TOR network</Text>
                        </Space>
                    </Select.Option>


                    <Select.Option value="MDEEnrolled">
                        <Space direction="vertical">
                            <Text>MDE Enrolled</Text>
                            <Text type="secondary">Agent that has MDE installed</Text>
                        </Space>
                    </Select.Option>

                    <Select.Option value="Interactive">
                        <Space direction="vertical">
                            <Text>Interactive agent</Text>
                            <Text type="secondary">Agent with a full user interface</Text>
                        </Space>
                    </Select.Option>
                </Select>
            </Form.Item>
        );
    }

    if(inputType==="String")
    {
        // Determine if input validated or not
        if (Meta.Options == undefined || Meta.Options == null)
        {

            return (
                <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                    <PlaybookTextInput DependencyOutputs={DependencyVariables} PlaybookVariables={PlaybookVariables}/>
                </Form.Item>
            )
        } else {
            return (
                <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                    <Select
                        mode={Meta.Multiple ? "multiple" : undefined}
                        allowClear={true}
                        defaultValue={null}>
                        { Meta.Options && Meta.Options.map((op) => {

                            return (
                                <Select.Option value={op}>{op}</Select.Option>
                            )

                        })}
                    </Select>
                </Form.Item>
            )
        }

    }

    if(inputType==="HTML")
    {
        return  <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
            <HTMLInput DependencyOutputs={DependencyVariables} PlaybookVariables={PlaybookVariables} showVariableButton={true}/>
        </Form.Item>
    }

    if(inputType==="Boolean")
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <SwitchWrapper />
            </Form.Item>
        )
    }

    if(inputType==="Integer")
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <InputNumber min={0}/>
            </Form.Item>
        )
    }

    if(inputType==="FileGenerationOptions")
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <FileGenerationSelector />
            </Form.Item>
        )
    }

    if(inputType==="DefenderAlert" && Meta.Multiple)
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <ExpectedAlertsInput />
            </Form.Item>
        )
    }

    if(inputType==="EmailReadAction" && Meta.Multiple)
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <EmailActionInput />
            </Form.Item>
        )
    }

    if(inputType==="WebSessionAction" && Meta.Multiple)
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <WebSessionAction VariablesPlaybook={PlaybookVariables} VariablesDependencies={DependencyVariables} />
            </Form.Item>
        )
    }

    if(inputType==="SitePayload")
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <WebsitePayloadFormItem VariablesPlaybook={PlaybookVariables} VariablesDependencies={DependencyVariables} UseTypeString="_t"/>
            </Form.Item>
        )
    }

    if(inputType==="HashTable")
    {
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>
                <HashTableInput KeyLabel="Key" ValueLabel="Value"/>
            </Form.Item>
        )
    }

    if(inputType==="entity")
    {
        console.dir(optionsPlaybook);
        return (
            <Form.Item name={Meta.id} label={Meta.Name} tooltip={Meta.Description}>

                <Select
                    mode={Meta.Multiple ? "multiple" : undefined}
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    tagRender={(props) => {
                        const { value, closable, onClose } = props;

                        let label = props.label;

                        if(label === undefined)
                        {
                            label = "Static Defined"
                        }

                        return (
                            <Tag
                                closable={closable}
                                onClose={onClose}>
                                {label}
                            </Tag>
                        );
                    }}
                    options={[
                        {
                            label: "Playbook Variables",
                            options: optionsPlaybook
                        },
                        {
                            label: "Dependency Outputs",
                            options: optionsDependencies
                        },
                        {
                            label: "Static",
                            options: optionsStatic
                        },
                    ]}
                />


            </Form.Item>
        )
    }

    const defineStaticEntity = (t) => {
        message.info("defining static entity for " + t);
    }

    return <></>
};

export default TaskItemInput