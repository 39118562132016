import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {Button, Dropdown, Menu, message, Modal, Space, Table, Tag, Typography} from "antd";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
import DrawerRef from "../../../../classes/DrawerRef/DrawerRef";
import LabEnvironmentUserAddDrawer from "./LabEnvironmentUserAddDrawer";
import {
    DeleteOutlined,
    DownOutlined,
    InfoCircleOutlined,
    PlusCircleOutlined,
    UserOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LabEnvironmentUserEditDrawer from "./LabEnvironmentUserEditDrawer";
import LabUserType from "../../../../Models/Labs/LabUserType";
import AdminDisplay from "../../../admins/AdminDisplay";
import LabUserAttestationText from "./LabUserAttestationText";
import Text from "antd/es/typography/Text";


export interface LabEnvironmentUsersTableProps {
    showAddButton?: boolean;
}

const LabEnvironmentUsersTable = ({showAddButton = true}: LabEnvironmentUsersTableProps) =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refUserAddDrawer = useRef<DrawerRef>(null);
    const refUserEditDrawer = useRef<DrawerRef>(null);

    const {
        data: LabUsers,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["labusers"],
        async () => await APIT.labUsersGet()
    );

    const handleRemove = (id:string) => {
        Modal.confirm({
            title: 'Confirm removal',
            icon: <InfoCircleOutlined />,
            content: <>
                <Text>Deleting a Lab User is permanent. Accounts are kept for record purposes but the underlying user will be removed.</Text>
            </>,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: async () => {
                const hide = message.loading('Marking user for removal ' + id);

                APIT.labUserDelete(id).then(() => {
                    hide();
                    refetch();
                }).catch(() => {
                    hide();
                })
            }
        });
    }

    // return on change properties

    return (
        <>

            <LabEnvironmentUserAddDrawer ref={refUserAddDrawer} onChange={refetch} />
            <LabEnvironmentUserEditDrawer ref={refUserEditDrawer} onChange={refetch} />

            {showAddButton &&
                <Button icon={<PlusCircleOutlined />} onClick={() => refUserAddDrawer.current !== null ? refUserAddDrawer.current.showDrawer() : null}>Add</Button>
            }

            <Table
                dataSource={LabUsers}
                rowKey="id"
                pagination={false}
                loading={isLoading}
                columns={[
                    {
                        title: "UPN",
                        key: 'UPN',
                        width:400,
                        render: (record) => {
                            return <Space>
                                <FontAwesomeIcon icon={faUser} />
                                <Typography.Text>{record.UserPrincipalName}</Typography.Text>
                            </Space>
                        },
                    },
                    {
                        title: "Owner",
                        key: 'OwnerID',
                        width:400,
                        render: (record) => {

                            if(record.UserType == LabUserType.Managed)
                            {
                                return <AdminDisplay Admin={record.OwnerID} showAvatar={true} showName={true} />
                            }

                            if(record.UserType == LabUserType.Unmanaged)
                            {
                                return <Tag icon={<WarningOutlined />} color={"warning"}>
                                    Unmanaged
                                </Tag>
                            }

                            return <Space>
                                <Tag icon={<UserOutlined />} color={"success"}>
                                    Managed Identity
                                </Tag>
                            </Space>
                        }
                    },
                    {
                        title: "Attestation",
                        key: 'Attestation',
                        render: (record) => {

                            let userId:string = record.id;

                            if (record.UserType == LabUserType.Managed) {
                                // @ts-ignore
                                return <LabUserAttestationText LabUserID={userId}/>
                            }

                            return <></>
                        }
                    },
                    {
                        key:'actions',
                        render:(record) => {
                            return (
                                <>

                                    <Dropdown overlay={

                                        <Menu>

                                            <Menu.Item key="edit" onClick={() => {
                                                if(refUserEditDrawer !== null && refUserEditDrawer.current !== null) {
                                                    refUserEditDrawer.current.showDrawer(record.id)
                                                }
                                            }} icon={<UserOutlined/>}>
                                                Edit
                                            </Menu.Item>

                                            <Menu.Item key="edit" onClick={() => handleRemove(record.id)} icon={<DeleteOutlined/>}>
                                                Remove
                                            </Menu.Item>
                                        </Menu>

                                    }>
                                        <Button>
                                            <DownOutlined/>
                                        </Button>
                                    </Dropdown>

                                </>
                            )
                        }
                    }
                ]}
            />
        </>
    )
}

export default LabEnvironmentUsersTable;