import {
    Button,
    Card,
    Col,
    Dropdown,
    Menu,
    message,
    Modal,
    Row,
    Space,
    Spin,
    Statistic,
    Table,
    Tag,
    Typography
} from "antd";
import React, {useContext, useRef} from "react";
import {
    ArrowDownOutlined,
    ArrowUpOutlined, CheckCircleOutlined, CheckOutlined,
    DeleteOutlined, DownOutlined, InfoCircleOutlined,
    PlusCircleOutlined,
    UserOutlined,
    WarningOutlined
} from "@ant-design/icons";
import LabEnvironmentUsersTable from "../../lab/user/components/LabEnvironmentUsersTable";
import PageHeader from "../../misc/PageHeader";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import LabEnvironmentUserAddDrawer from "../../lab/user/components/LabEnvironmentUserAddDrawer";
import LabEnvironmentUserEditDrawer from "../../lab/user/components/LabEnvironmentUserEditDrawer";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import LabUserType from "../../../Models/Labs/LabUserType";
import AdminDisplay from "../../admins/AdminDisplay";
import LabUserAttestationText from "../../lab/user/components/LabUserAttestationText";
import Text from "antd/es/typography/Text";
import LabUserState from "../../../Models/Labs/LabUserState";
import dtoLabUserNew from "../../../Models/Labs/dtoLabUserNew";

const HomeMyLabUsers = () => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    const {
        data: MyLabUsers,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["MyLabUsers"],
        async () => await APIT.myLabUsersGet()
    );

    const {
        data: LabEnvironments,
        isLoading: isLoadingEnvironments,
        refetch: refetchEnvironments
    } = useQuery(
        ["labenvironments"],
        async () => await APIT.labsGet()
    );


    const handleRemove = (id:string) => {
        Modal.confirm({
            title: 'Confirm removal',
            icon: <InfoCircleOutlined />,
            content: <>
                <Text>Deleting a Lab User is permanent. Accounts are kept for record purposes but the underlying user will be removed.</Text>
            </>,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: async () => {
                const hide = message.loading('Marking user for removal ' + id);

                APIT.labUserDelete(id).then(() => {
                    hide();
                    refetch();
                }).catch(() => {
                    hide();
                })
            }
        });
    }

    return (
        <>
            <PageHeader
                Title="My Lab Users"
            />

            <Table
                dataSource={MyLabUsers}
                rowKey="id"
                pagination={false}
                loading={isLoading}
                columns={[
                    {
                        title: "UPN",
                        key: 'UPN',
                        width:400,
                        render: (record) => {
                            return <Space>
                                <FontAwesomeIcon icon={faUser} />
                                <Typography.Text>{record.UserPrincipalName}</Typography.Text>
                            </Space>
                        },
                    },
                    {
                        title: "Lab",
                        key: 'LabID',
                        width:400,
                        render: (record) => {

                            if(LabEnvironments === undefined)
                            {
                                return <Spin />
                            } else {
                                const lab = LabEnvironments.find((element) => element.id === record.LabID);

                                return <Typography.Text>{lab?.Name}</Typography.Text>
                            }
                        }
                    },
                    {
                        title: "State",
                        key: 'State',
                        render: (record) => {

                            console.log(record.State);

                            if(record.State === LabUserState.New || record.State == LabUserState.Disabled)
                            {
                                return <Tag>Disabled (Attestation Required)</Tag>
                            }

                            if(record.State === LabUserState.Warn)
                            {
                                return <Tag icon={<InfoCircleOutlined/>} color="warning">Warn (Attest Needed)</Tag>
                            }

                            if(record.State === LabUserState.Active)
                            {
                                return <Tag icon={<CheckCircleOutlined/>} color="success">Active</Tag>
                            }

                            return <Tag>Unknown</Tag>
                        }
                    },
                    {
                        key:'actions',
                        render:(record) => {
                            return (
                                <>

                                    <Dropdown overlay={

                                        <Menu>

                                            <Menu.Item key="remove" onClick={() => handleRemove(record.id)} icon={<DeleteOutlined/>}>
                                                Remove
                                            </Menu.Item>
                                        </Menu>

                                    }>
                                        <Button>
                                            <DownOutlined/>
                                        </Button>
                                    </Dropdown>

                                </>
                            )
                        }
                    }
                ]}
            />
        </>

    )
}

export default HomeMyLabUsers;