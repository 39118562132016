import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Button, Drawer, Form, Input, Modal, Select, Slider, Space, Spin, Switch, Tabs} from "antd";
import {BulbOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useForm} from "antd/es/form/Form";
import {useQuery} from "@tanstack/react-query";
import TenantSelect from "../../tenants/TenantSelect/TenantSelect.react";

const IdentityAddDrawer = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // State
    const [Open, SetOpen] = useState(false);
    const [Simulated, SetSimulated] = useState(false);
    const [RandomIdentity, SetRandomIdentity] = useState(false);
    const [AddAccount, SetAddAccount] = useState(null);
    const [SelectedTenant, SetSelectedTenant] = useState(null);

    // Refs
    const [form] = useForm();

    const {
        data: Behaviours,
        isLoading: isLoadingBehaviours,
    }  = useQuery({
            queryKey: ["behaviours"],
            queryFn: async() => await API.getUserBehaviours()
        }
    );

    const {
        data: Tenants,
        isLoading: isLoadingTenants,
    }  = useQuery({
            queryKey: ["tenants"],
            queryFn: async() => await API.getTenants()
        }
    );

    const {
        data: TenantDomains,
        isLoading: isLoadingTenantDomains,
    }  = useQuery({
            queryKey: ["tenantdomains", SelectedTenant],
            queryFn: async() => await API.getTenantDomains(SelectedTenant),
            enabled: (SelectedTenant === null) ? false : true
        }
    );

    // Funcs
    const showDrawer = (id) => {
        reset();
        SetOpen(true);
    }

    const reset = () => {
        form.resetFields();
        SetSimulated(false);
        SetRandomIdentity(false);
        SetAddAccount(null);
        SetSelectedTenant(null);
    }

    const handleSave = async () => {
        // Create dto edit object

        let dtoAddObject = form.getFieldsValue(true);

        // Set add account props
        if(AddAccount==="m365")
        {
            dtoAddObject.AddSMTPAccount = false;
            dtoAddObject.AddM365Account = true;
        }

        if(AddAccount==="smtp")
        {
            dtoAddObject.AddSMTPAccount = true;
            dtoAddObject.AddM365Account = false;
        }

        const modal = Modal.success({
            title: 'Adding',
            content: `The identity is being added. If you have specified M365 provisioning, this might take a minute.`,
        });

        console.dir(dtoAddObject);

        await API.addIdentity(dtoAddObject).then(modal.destroy);

        handleClose();

        props.onChange();
    }

    const handleClose = () => {
        SetOpen(false);
    }

    return (
        <Drawer
            {...props}
            title="Add New Identity"
            width={800}
            closeable={true}
            onClose={handleClose}
            extra={
                <Space>
                    <Button type="primary" icon={<SaveOutlined/>} onClick={handleSave}>Add</Button>
                </Space>
            }
            open={Open}>

            <Form
                form={form}
                labelAlign="right"
                labelWrap={true}
                labelCol={{ span: 6 }}>

                <Tabs defaultActiveKey="detail">
                    <Tabs.TabPane
                        tab={
                            <span><BulbOutlined/>Details</span>
                        }
                        key="taskitemprop">

                        <Form.Item name="RandomIdentity" label="Random Identity" tooltip="Creates random identity data, such as random username, random firstname, department etc.">
                            <Switch onChange={SetRandomIdentity}/>
                        </Form.Item>

                        <Form.Item name="Tenant" label="Tenant">
                            <TenantSelect onChange={SetSelectedTenant} onBaseDomainChange={(baseDomain) => form.setFieldValue("Email", baseDomain)}/>
                        </Form.Item>

                        {RandomIdentity &&
                            <Form.Item name="RandomIdentityDomain" label="Random Identity Domain"
                                       tooltip="Domain to use for identity">
                                <Select defaultValue="">

                                    <Select.Option value="">Default Tenant Domain</Select.Option>

                                    {TenantDomains && TenantDomains.map((domain) => <Select.Option value={domain}>{domain}</Select.Option>)}

                                </Select>
                            </Form.Item>
                        }

                        {!RandomIdentity &&
                            <Form.Item name="Email" label="Email">
                                <Input placeholder="joe@contoso.com" />
                            </Form.Item>
                        }

                        {!RandomIdentity &&
                            <Form.Item name="DefaultPassword" label="Default Password">
                                <Input.Password placeholder="input password"/>
                            </Form.Item>
                        }

                        <Form.Item name="Simulated" label="Use in simulations" tooltip="Simulations cause this user to send email traffic by itself">
                            <Switch onChange={SetSimulated}/>
                        </Form.Item>

                        {Simulated &&
                            <Form.Item name="BehaviourID" label="Simulation Behaviour">
                                <Select>
                                    {Behaviours && Behaviours.map((b) => <Select.Option value={b.id}>{b.id}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        }

                        {Simulated &&
                            <Form.Item name="RandomRelationships" label="Random Relationships" tooltip="Set up random relationships for this user. More relationships a user has, the more mail that will flow.">
                                <Slider defaultValue={0} max={20} />
                            </Form.Item>
                        }

                        <Form.Item name="AddAccount" label="Add Account" tooltip="Adds an account to this identity">
                            <Select onChange={SetAddAccount}>
                                <Select.Option value="m365">M365 Account</Select.Option>
                                <Select.Option value="smtp">SMTP Account</Select.Option>
                            </Select>
                        </Form.Item>

                        {AddAccount==="m365" &&
                            <Form.Item name="ProvisionM365Account" label="Provision M365 Account"
                                       tooltip="Attempts to provision the M365 account">
                                <Switch/>
                            </Form.Item>
                        }

                        <Form.Item name="Tags" label="Tags">
                            <Select mode="tags" />
                        </Form.Item>

                    </Tabs.TabPane>
                </Tabs>
            </Form>

        </Drawer>

    )

});

export default IdentityAddDrawer;