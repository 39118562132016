import React, {useContext, useEffect, useState} from 'react'
import './attest.css';
import {Alert, Button, Card, Checkbox, Collapse, Divider, Form, Image, Input, List, Space, Tag, Typography} from 'antd';

import imgLogo from "../img/logo.webp"
import {useParams} from "react-router-dom";
import AttestAPI from "../service/attestapi";
import APIProvider from "../service/api2";

// Login front page
const AttestPage = (props) => {

    var params = new URLSearchParams(window.location.search)

    var id = params.get('id');

    const API = new AttestAPI(props);
    const [form] = Form.useForm();
    const [Attested, SetAttested] = useState(false);
    const [AttestationData, SetAttestationData] = useState(undefined);
    const [AttestationResponse, SetAttestationResponse] = useState();

    const handleAttest = (values) => {
        console.dir(values);
        API.attest(id, values).then((r) => {
            SetAttested(true);
            SetAttestationResponse(r);
        })
    }

    useEffect(() => {
        API.getAttestationData(id).then((r) => {
            SetAttestationData(r);
        })
    }, [id]);

    if(id!==undefined && AttestationData !== undefined)
    {

        return (
            <div className="attest-page">
                <div className="attest-form">
                    <Space direction="vertical" size="large">

                        <Image src={imgLogo} preview={false} width={179} height={50}/>

                        <Typography.Title level={2}>Lab Name: {AttestationData.LabName}</Typography.Title>

                        {Attested &&
                            <Typography.Title level={3}>Thank you!</Typography.Title>
                        }

                        {AttestationResponse && AttestationResponse.IsNewAccount &&
                            <>
                            <Typography.Text>Your account has been enabled and your TEMPORARY password is below. You must login within the next hour and will be required to change your password and set up second factors of authentication. The password below is not saved.</Typography.Text>
                            <Typography.Title level={2}>{AttestationResponse.TemporaryPassword}</Typography.Title>
                            <Typography.Text>Remember, do NEVER share credentials.</Typography.Text>
                            </>
                        }

                        {!Attested && AttestationData.Required && <>
                        <Typography.Title level={3}>Security Brief</Typography.Title>
                        <ul>
                            <li>
                                CAttack Labs should be used for testing, lab and demonstration purposes only.
                            </li>
                            <li>
                                All accounts must have secure passwords
                            </li>
                            <li>
                                All accounts must have multifactor authentication, accounts without MFA are disabled
                            </li>
                            <li>
                                Credentials are not to be shared
                            </li>
                            <li>
                                Credentials are never to be stored clear text anywhere. Use an approved password manager.
                            </li>
                            <li>
                                No business data AT ALL can be located on this tenant.
                            </li>
                            <li>
                                You understand any data stored in this account can be wiped without notice.
                            </li>
                        </ul>

                        <p><strong>Failure to adhere to your attestation will result in accounts being disabled, and managers being contacted. These are serious security violations.</strong></p>

                        <Typography.Title level={3}>Attestation</Typography.Title>

                        <Form form={form} name="attestation" layout="vertical" autoComplete="off" onFinish={handleAttest}>
                            <Form.Item
                                name="Name"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="BusinessCase"
                                label="Business Case"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        min: 10,
                                        message: "Please enter a valid business case, be descriptive."
                                    }
                                ]}>
                                <Input />
                            </Form.Item>


                            <Form.Item
                                name="agreement"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                    },
                                ]}>
                                <Checkbox>
                                    I agree to the conditions of use
                                </Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Attest
                                </Button>
                            </Form.Item>
                        </Form>
                        </>
                    }

                        {!Attested && !AttestationData.Required &&
                            <Typography.Title level={3}>Thank you!</Typography.Title>
                        }

                        {!Attested && !AttestationData.Required && !AttestationData.AdditionalInfo &&
                            <Typography.Text>Attestation is not required on this acocunt.</Typography.Text>
                        }

                        {!Attested && !AttestationData.Required && AttestationData.AdditionalInfo &&
                            <Typography.Text>{AttestationData.AdditionalInfo}</Typography.Text>
                        }

                    </Space>

                </div>
            </div>

        )
    }

}

export default AttestPage