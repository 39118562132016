import React, {useContext, useRef} from 'react'
import {useQuery} from "@tanstack/react-query";
import PageHeader from "../misc/PageHeader";
import APIProvider from "../../service/api2";
import {AppContext} from "../../AppProvider";
import {Button, Dropdown, message, Modal, Switch, Table, Tag} from "antd";
import {
    CheckOutlined, CloseOutlined, CopyOutlined, DeleteOutlined, DownOutlined, EditOutlined, ExclamationCircleOutlined,
    PlusCircleOutlined
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import APITProvider from "../../service/api";
import AdminDisplay from "../admins/AdminDisplay";
import WebsitePayloadTag from "./WebsitePayloadTag";
import {WebsiteDetailModal, WebsiteDetailModalRef} from "./WebsiteDetailModal/WebsiteDetailModal";
import Website from "../../Models/Websites/Website";
import DrawerRef from "../../classes/DrawerRef/DrawerRef";
import WebsiteAddDrawer from "./WebsiteAddDrawer";
import DateTimeView from "../misc/DateTimeView";
import AttackTag from '../attack/AttackTag/AttackTag';

const WebsitesPage = (props:any) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token,true);
    const APIT = new APITProvider(token,true);

    const PlaybookAddDrawerRef = useRef();
    const PlaybookRunModal = useRef();

    // React Query

    const {
        data: Websites,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["websites"],
        async () => await APIT.websitesGet()
    );

    // Create ref for websitedetailmodal
    const refWebsiteDetail = useRef<WebsiteDetailModalRef>(null);
    const refWebsiteAddDrawer = useRef<DrawerRef>(null);

    const weaponizeSelect = (site: Website) => API.weaponizeRedirectUrl(site.id,!site.weaponized).then(() => refetch());

    const onWebsiteAdd = () => {
        refetch();
    }

    // @ts-ignore
    return (
        <>
            <PageHeader
                Title="Websites"
                Buttons={
                    <Button icon={<PlusCircleOutlined />} onClick={() => refWebsiteAddDrawer.current !== null ? refWebsiteAddDrawer.current.showDrawer() : null}>Add</Button>
                }
            />

            <WebsiteAddDrawer ref={refWebsiteAddDrawer} onChange={onWebsiteAdd}/>
            <WebsiteDetailModal ref={refWebsiteDetail}/>

            <Table
                dataSource={Websites}
                rowKey="id"
                pagination={false}
                loading={isLoading}
                columns={[
                    {
                        key:'avatar',
                        width:50,
                        render: (record) => <AdminDisplay Admin={record.Operator} showAvatar={true} showName={false}/>
                    },
                    {
                        title: "ID",
                        key: 'id',
                        dataIndex: 'id',
                        render: (text) => text,
                    },
                    {
                        title: 'Current Payload',
                        key: 'currentPayload',
                        render: (record) => <WebsitePayloadTag Weaponized={record.weaponized} Payload={record.weaponized ? record.WeaponizedPayload : record.NormalPayload}/>
                    },
                    {
                        title: 'Weaponized',
                        key: 'weaponized',
                        render: (record) =>
                            <Switch
                                onClick={() => weaponizeSelect(record)}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={record.weaponized}/>
                    },
                    {
                        title:'Created',
                        dataIndex: 'CreationDate',
                        key:'actions',
                        render:(text) => <DateTimeView DateTime={text} type="secondary"/>

                    },
                    {
                        title:'Type',
                        dataIndex: 'AttackID',
                        key:'AttackID',
                        render:(text) => text !== null ? <AttackTag AttackID={text} /> : <Tag>Manual</Tag>

                    },
                    {
                        title: 'Sessions',
                        key: 'sessions',
                        render: (record) => <Text type="secondary">{record.SessionCount}</Text>
                    },
                    {
                        key:'actions',
                        render:(record) => {

                            let items = [
                                {
                                    label: 'Copy a live URL',
                                    icon: <CopyOutlined />,
                                    key: 'copyurl',
                                },
                                {
                                    label: 'Detail',
                                    icon: <EditOutlined/>,
                                    key: 'detail',
                                },
                                {
                                    label: 'Delete',
                                    icon: <DeleteOutlined/>,
                                    key: 'Delete',
                                }
                            ];

                            const onClick = (key:any) => {

                                if(key.key==="copyurl" && refWebsiteDetail !== null && refWebsiteDetail.current !== null)
                                {
                                    // Construct a URL
                                    var constructedUrl = "http://" + record.id + record.Endpoints[0];
                                    navigator.clipboard.writeText(constructedUrl);
                                    message.success("Copied a live URL to clipboard: " + constructedUrl);
                                }

                                if(key.key==="detail" && refWebsiteDetail !== null && refWebsiteDetail.current !== null)
                                {
                                    refWebsiteDetail.current.showModal(record.id);
                                }

                                if(key.key==="Delete")
                                {
                                    // Confirm delete
                                    Modal.confirm({
                                        title: 'Confirm delete',
                                        icon: <ExclamationCircleOutlined />,
                                        content: <>
                                            <Text>Deleting </Text>
                                            <Text strong>{record.id} </Text>
                                            <Text>is permanent. It may break an attack!</Text>
                                        </>,
                                        okText: 'Delete',
                                        cancelText: 'Cancel',
                                        onOk: () => API.deleteRedirectUrl(record.id).then(() => refetch())
                                    });
                                }
                            }

                            return (

                                <Dropdown menu={{ items, onClick }}>
                                    <Button onClick={(e) => e.preventDefault()}>
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            )
                        }
                    }
                ]}
            />
        </>
    );

}
export default WebsitesPage;