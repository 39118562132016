import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Dropdown, Menu, message, Space, Table, Tag} from 'antd';
import Text from "antd/es/typography/Text";
import date from "date-and-time";
import {
    AlertOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    RetweetOutlined,
    StopOutlined,
    WarningOutlined
} from "@ant-design/icons";
import AttackStatusIcon from "./AttackStatusIcon";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import AttackDetailModal from "./AttackDetail/AttackDetailModal";
import AdminDisplay from "../admins/AdminDisplay";
import {RefreshOutlined} from "@mui/icons-material";
import {useQueryClient} from "@tanstack/react-query";
import AttackTCRTag from "./AttackTCR/AttackTCRTag";

const AttacksTable = (props) =>
{

    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // Create ref for attackdetailmodal
    const AttackDetail = useRef(null);

    // React query client
    const queryClient = useQueryClient();

    // State
    const [Loading,SetLoading] = useState(false);
    const [CorrelatedIncidents,SetCorrelatedIncidents] = useState([]);
    const [CorrelatedIncidentsLoading,SetCorrelatedIncidentsLoading] = useState(false);

    const replay = (id) => {
        API.replayAttack(id).then((pe) => {
            props.onAttackChange(pe.id);
        })
    }

    const refresh = (id) => {
        API.AttackRefresh(id).then(() => {
            props.onAttackChange(id);
        })
    }

    const cancel = (id) => {
        API.attackCancel(id).then((pe) => {
            props.onAttackChange(id);
        })
    }


    const del = (id) => {
        API.deleteAttack(id).then(() => {
            props.onAttackChange(id);
        })
    }

    // Create an over arching loading param
    useEffect(() => {
        if((props.Loading || CorrelatedIncidentsLoading) && !Loading)
        {
            SetLoading(true);
        }

        if((!props.Loading && !CorrelatedIncidentsLoading) && Loading)
        {
            SetLoading(false);
        }
    },[props.Loading,CorrelatedIncidentsLoading]);

    // Load correlated incidents
    useEffect(() => {

        // When loading goes from true to false, if correlated incidents is empty, get correlated incidents
        if(!props.Loading && !CorrelatedIncidentsLoading && CorrelatedIncidents.length === 0)
        {
            SetCorrelatedIncidentsLoading(true);
            API.getCorrelatedDefIncidents().then((r) => {
                SetCorrelatedIncidents(r);
                SetCorrelatedIncidentsLoading(false);
            });
        }

    },[props.Loading]);

    return (
        <>
            <AttackDetailModal ref={AttackDetail}/>
            <Table
                pagination={{ pageSize: 15 }}
                dataSource={props.Attacks}
                rowKey="id"
                loading={Loading}
                columns={[
                    {
                        key:'avatar',
                        width:50,
                        render: (record) => <AdminDisplay Admin={record.Operator} showAvatar={true} showName={false}/>
                    },
                    {
                        title: 'Name',
                        dataIndex: 'Name',
                        key: 'name',
                        render: text => <Text>{text}</Text>,
                    },
                    {
                        title: 'Scheduled',
                        key: 'Scheduled',
                        render: record => {

                            const dt = new Date(record.StartTime);

                            const schTimeString = date.format(dt, "h[:]mm A");
                            const schDateString = date.format(dt, "ddd D MMMM");

                            return (
                                <Space direction="vertical">
                                    <Text>{schTimeString}</Text>
                                    <Text type="secondary">{schDateString}</Text>
                                </Space>
                            )
                        },
                    },
                    {
                        title: 'Tasks',
                        key: 'tasks',
                        render: (record) =>
                            <Text type="secondary">{record.countTasksComplete} of {record.countTasks} complete</Text>
                    },
                    {
                        key: 'incidents',
                        render: (record) =>
                        {
                            const CorrelatedIncident = CorrelatedIncidents.find(x => x.AttackID === record.id);
                            let hasIncident = false;
                            let missingAlerts = false;

                            if(record.ExpectedAlertState === 1)
                            {
                                missingAlerts=true;
                            }

                            if(CorrelatedIncident!==null && CorrelatedIncident!== undefined)
                            {
                                hasIncident = true;
                            }

                            return (
                                <>
                                    {hasIncident && <Tag icon={<AlertOutlined />}>Defender Incident {CorrelatedIncident.IncidentId}</Tag>}
                                    {missingAlerts && <Tag icon={<WarningOutlined/>} color="error">Alerts Missing</Tag> }
                                    <AttackTCRTag AttackID={record.id} />
                                </>
                            )
                        }
                    },
                    {
                        title: 'Status',
                        key: 'status',
                        render: (record) => <AttackStatusIcon Attack={record}/>
                    },
                    {
                        key:'actions',
                        render:(record) => {
                            return (
                                <Dropdown overlay={

                                    <Menu>
                                        <Menu.Item key="detail"
                                                   onClick={() => AttackDetail.current.showModal(record.id)}>
                                            <ExclamationCircleOutlined/> Detail
                                        </Menu.Item>

                                        <Menu.Item key="copy"
                                                   onClick={() => {
                                                       navigator.clipboard.writeText(record.id);
                                                       message.success("Copied Task ID to clipboard");
                                                   }}>
                                            <CopyOutlined/> Copy Attack ID to Clipboard
                                        </Menu.Item>

                                        <Menu.Divider/>

                                        <Menu.Item key="delete" onClick={() => del(record.id)} icon={<DeleteOutlined/>}>
                                            Delete
                                        </Menu.Item>

                                        <Menu.Item key="replay" onClick={() => replay(record.id)} icon={<RetweetOutlined/>}>
                                            Replay
                                        </Menu.Item>

                                        <Menu.Item key="refresh" onClick={() => refresh(record.id)} icon={<RefreshOutlined/>}>
                                            Trigger Refresh
                                        </Menu.Item>

                                        <Menu.Item key="cancel" onClick={() => cancel(record.id)} icon={<StopOutlined/>}>
                                            Cancel
                                        </Menu.Item>

                                    </Menu>

                                }>
                                    <Button>
                                        <DownOutlined/>
                                    </Button>
                                </Dropdown>
                            )
                        }

                    }
                ]}
            />
        </>
    )
}

export default AttacksTable;