import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {Col, Form, Input, Row, Select, Space, Switch, Tag} from "antd";
import FileReferenceSelector from "../../files/FileReferenceSelector";
import AgentSelect from "../../agents/AgentSelect";
import Text from "antd/es/typography/Text";
import IdentityInput from "../../PlaybookInputs/Identity/IdentityInput.react";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
const VariableValueEntries = (props) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    const {
        data: Identities,
        isLoading: isLoadingIdentities
    } = useQuery({
            queryKey: ["identities"],
            queryFn: async () => await API.getIdentities()
        }
    );

    const {
        data: Agents,
        isLoading: isLoadingAgents
    } = useQuery({
            queryKey: ["agents"],
            queryFn: async () => await API.getAgents()
        }
    );

    const {
        data: EmailTemplates,
        isLoading: isLoadingEmailTemplates
    } = useQuery({
            queryKey: ["emailtemplates"],
            queryFn: async () => await API.getTemplateEmails()
        }
    );

    return <>

        { props.Variables && props.Variables.map((pVar) => {

            console.dir(pVar);

            let rules = [{required: (pVar.Data === null ? true : false)}];

            if (pVar.Type === "FileReference") {
                return (
                    <Form.Item name={pVar.Name} label={pVar.Description} rules={rules}>
                        <FileReferenceSelector showHostedFiles={true} showTemplates={false}/>
                    </Form.Item>
                )
            }

            if (pVar.Type === "String") {
                return (
                    <Form.Item name={pVar.Name} label={pVar.Description} rules={rules}>
                        <Input/>
                    </Form.Item>
                )
            }

            if (pVar.Type === "Boolean") {
                return (
                    <Form.Item name={pVar.Name} label={pVar.Description} rules={rules}>
                        <Switch/>
                    </Form.Item>
                )
            }

            if (pVar.Type === "Agent")
            {
                return (
                    <Form.Item name={pVar.Name} label={pVar.Description} rules={rules}>
                        <AgentSelect Agents={Agents} loading={isLoadingAgents}/>
                    </Form.Item>
                )
            }

            if(pVar.Type=="EmailTemplate")
            {
                return (
                    <Form.Item name={pVar.Name} label={pVar.Description} rules={rules}>
                        <Select
                            loading={isLoadingEmailTemplates}
                            style={{width: '100%'}}
                            placeholder="Select an email template"
                            optionLabelProp="label">
                            {
                                EmailTemplates.map((et, i) => {

                                    var tagName = "Normal";
                                    var tagColor = "default";

                                    if (et.emailflags & 2) {
                                        var tagName = "Phish";
                                        var tagColor = "error";
                                    }

                                    if (et.emailflags & 16) {
                                        var tagName = "Malware";
                                        var tagColor = "error";
                                    }

                                    return (
                                        <Select.Option value={et.id} label={
                                            <Space>
                                                <Tag color={tagColor}>{tagName}</Tag>
                                                <Text>{et.name}</Text>
                                            </Space>
                                        }
                                        >
                                            <Row gutter={5} align="middle">
                                                <Col>
                                                    <Tag color={tagColor}>{tagName}</Tag>
                                                </Col>
                                                <Col>
                                                    {et.subject}
                                                </Col>
                                            </Row>
                                        </Select.Option>
                                    )
                                })
                            }

                        </Select>
                    </Form.Item>
                )
            }

            if(pVar.Type==="Identity")
            {
                return (
                    <Form.Item name={pVar.Name} label={pVar.Description} rules={rules}>
                        <IdentityInput />
                    </Form.Item>
                );
            }


        })}
    </>
}
export default VariableValueEntries;