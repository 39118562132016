import React, {useContext, useRef} from "react";
import {Button, Dropdown, Modal, Space, Table, Tag} from 'antd';
import Text from "antd/es/typography/Text";
import {
    CheckSquareOutlined,
    CloudOutlined,
    DeleteOutlined,
    DownOutlined,
    ExclamationCircleOutlined, GlobalOutlined, InfoCircleOutlined, LinkOutlined, MailOutlined,
} from "@ant-design/icons";
import {AppContext} from "../../AppProvider";
import {useQuery} from "@tanstack/react-query";
import APITProvider from "../../service/api";
import Domain from "../../Models/Domain/Domain";
import {ColumnFilterItem} from "antd/es/table/interface";
import HealthTag from "../health/HealthTag/HealthTag";

interface AgentTableProps {
    Agents:any,
    Loading: boolean,
    onChange?: () => void
}

const DomainsTable = () => {

        // Construct token
        const {token} = useContext(AppContext);
        const APIT = new APITProvider(token, true);

        const {
            data: Domains,
            isLoading: isLoading,
            refetch: refetch
        } = useQuery(
            ["domains"],
            async () => await APIT.getDomains()
        );

        const Delete = async (id:string) => {
            await APIT.domainDelete(id);
            await refetch();
        }

    const getFilterTags = () => {
        let array:ColumnFilterItem[] = [];

        if(Domains !== undefined)
        {
            Domains.forEach((d:Domain) => {
                if(d.Use !== null && d.Use !== undefined)
                {
                    d.Use.forEach((t:string) => {
                        if(!array.find(c => c.value == t))
                        {
                            array.push({
                                text: t,
                                value: t
                            })
                        }
                    })
                }
            });
        }

        return array;
    }

        return (
            <>
                <Table
                    dataSource={Domains}
                    rowKey="id"
                    pagination={false}
                    loading={isLoading}
                    columns={[
                        {
                            key:'icon',
                            width:50,
                            render: () => <CloudOutlined/>
                        },
                        {
                            title: "Name",
                            key: 'id',
                            render: (record) => {
                                if(record.Managed)
                                {
                                    return <Space><Text>{record.id}</Text><Tag>Managed</Tag></Space>
                                } else {
                                    return <Text>{record.id}</Text>
                                }

                            },
                        },
                        {
                            title: "Use",
                            key: 'Use',
                            filters: getFilterTags(),
                            onFilter: (value, record) => record.Use !== null && record.Use.includes(value as string),
                            render: (record:Domain) => {
                                return <>
                                    {
                                        record.Use && record.Use.map((t:string) => {
                                                let icon = <></>;
                                                let text = t;

                                                if(t==="Office365")
                                                {
                                                    icon = <GlobalOutlined />
                                                    text = "Office 365"
                                                }

                                                if(t==="SMTP")
                                                {
                                                    icon = <MailOutlined />
                                                    text = "SMTP"
                                                }

                                                if(t==="SiteFriendly")
                                                {
                                                    icon = <LinkOutlined />
                                                    text = "Site (Friendly)"
                                                }

                                                if(t==="SiteMalware")
                                                {
                                                    icon = <LinkOutlined />
                                                    text = "Site (Malware)"
                                                }

                                                if(t==="SitePhishing")
                                                {
                                                    icon = <LinkOutlined />
                                                    text = "Site (Phish)"
                                                }

                                                if(t==="SiteRedirect")
                                                {
                                                    icon = <LinkOutlined />
                                                    text = "Site (Redirect)"
                                                }

                                                return (<Tag icon={icon}>{text}</Tag>);
                                            }
                                        )
                                    }
                                </>


                            }
                        },
                        {
                            key: 'Healthy',
                            title: 'Healthy',
                            render:(record) => <HealthTag ObjectType={"Domain"} ObjectID={record.id}/>
                        },
                        {
                            key:'actions',
                            render:(record) => {

                                let items = [
                                    {
                                        label: 'Run Health Check',
                                        icon: <CheckSquareOutlined />,
                                        key: 'health',
                                    },
                                    {
                                        label: 'Delete',
                                        icon: <DeleteOutlined/>,
                                        key: 'Delete',
                                    }
                                ];

                                if(record.Managed)
                                {
                                    items.unshift({
                                        label: 'Re-run Deployment',
                                        icon: <CheckSquareOutlined />,
                                        key: 'redeploy',
                                    })
                                }

                                const onClick = (key:any) => {

                                    if(key.key==="health")
                                    {
                                        Modal.confirm({
                                            title: 'Confirm health check',
                                            icon: <InfoCircleOutlined />,
                                            content: <>
                                                <Text>Health checks are performed asynchronously. Once ran, come back to this page to check soon</Text>
                                            </>,
                                            okText: 'Run',
                                            cancelText: 'Cancel',
                                            onOk: async () => APIT.putHealthCheck("Domain", record.id)
                                        });
                                    }

                                    if(key.key==="redeploy")
                                    {
                                        Modal.confirm({
                                            title: 'Force Redeploy',
                                            icon: <InfoCircleOutlined />,
                                            content: <>
                                                <Text>Deployment is ran asynchronously. Once ran, come back to this page to check soon</Text>
                                            </>,
                                            okText: 'Run',
                                            cancelText: 'Cancel',
                                            onOk: async () => APIT.domainDeployPut(record.id)
                                        });
                                    }

                                    if(key.key==="Delete")
                                    {
                                        // Confirm delete
                                        Modal.confirm({
                                            title: 'Confirm delete',
                                            icon: <ExclamationCircleOutlined />,
                                            content: <>
                                                <Text>Deleting </Text>
                                                <Text strong>{record.id} </Text>
                                                <Text>is permanent.</Text>
                                            </>,
                                            okText: 'Delete',
                                            cancelText: 'Cancel',
                                            onOk: () => Delete(record.id)
                                        });
                                    }
                                }

                                return (

                                    <Dropdown menu={{ items, onClick }}>
                                        <Button onClick={(e) => e.preventDefault()}>
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                )
                            }
                        }
                    ]}
                />
            </>

        )
    }

export default DomainsTable;